<template>
  <form
    v-if="Boolean(sortedLocalesByDefaultFirst.length)"
    id="lang-switcher"
    class="flex items-center justify-start gap-2"
    :title="$t('ui_language_switch')"
  >
    <svg
      v-if="locales.length > 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      class="w-8 h-8 fill-current"
      :class="variant === 'dark' ? 'text-brand-white' : 'text-brand-black'"
    >
      <g
        fill="none"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      >
        <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0-18 0m.6-3h16.8M3.6 15h16.8" />
        <path d="M11.5 3a17 17 0 0 0 0 18m1-18a17 17 0 0 1 0 18" />
      </g>
    </svg>

    <button
      v-for="(item) in sortedLocalesByDefaultFirst"
      :key="(item as unknown as string)"
      type="button"
      :title="item.name"
      :class="[
        {'is-active': item.code === locale},
        variant === 'dark' ? 'hover:text-brand-white' : 'hover:text-brand-primary'
      ]"
      :disabled="item.code === locale"
      @click="setLang(item.code)"
    >
      {{ item.code }}
    </button>
  </form>
</template>

<script setup lang="ts">
import { computed, inject } from 'vue';
import { useI18n, useRoute, useRouter, useRuntimeConfig } from '#imports';
import { useI18nStore } from '~/stores/i18nStore';
import type { WebSettings } from '~/types';

const webSettings = inject('settings') as Record<any, any>;
const { DEFAULT_LOCALE } = useRuntimeConfig().public;

withDefaults(defineProps<{
  variant?: 'light' | 'dark'
}>(), {
  variant: 'light',
});

const i18nStore = useI18nStore();
const { locale, locales, setLocale } = useI18n();
const route = useRoute();
const router = useRouter();

function setLang (lang: string) {
  const routeName = route?.name as string || '';
  if (routeName?.startsWith('slug__')) {
    const route = i18nStore.pageTranslations?.[lang] as string;
    if (!route) {
      return console.warn('Translation of current route doesn\'t exists:', routeName);
    }
    return router.push(route);
  }

  return setLocale(lang);
};

const sortedLocalesByDefaultFirst = computed(() => {
  const settings = webSettings.settings as WebSettings;
  const languages = locales.value.filter(lang => settings.available_locales.includes(lang?.code));
  if (languages.length < 2) return languages;

  const index = languages.findIndex(lang => lang.code === DEFAULT_LOCALE);
  if (index !== -1) {
    const language = languages.splice(index, 1)[0];
    languages.unshift(language);
  }

  return languages;
});
</script>

<style scoped>
button {
  @apply uppercase font-bold text-lg transition-colors duration-300 p-1;
  &:not(.is-active) {
    @apply text-gray-400;
  }
  &.is-active {
    @apply text-brand-primary;
  }
}
</style>
