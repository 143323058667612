<template>
  <header
    class="lg:px-10 p-6"
    :class="variant === 'light' ? 'text-brand-white' : 'text-brand-black'"
  >
    <div
      class="fixed top-0 left-0 w-full bg-brand-white shadow-lg z-[20] transition-transform duration-700 ease-in-out transform"
      :class="stickyHeaderVisible ? 'translate-y-0' : '-translate-y-80'"
      :aria-hidden="!stickyHeaderVisible"
    >
      <nav
        v-if="stickyNavbar"
        class="navbar navbar__fixed w-full text-brand-black px-4"
        aria-label="Global navbar"
      >
        <div class="pr-20 min-h-16 flex items-center">
          <BaseCompanyLogo img-class="max-h-20 max-w-40 w-auto py-1" />
        </div>
        <div class="flex lg:hidden">
          <button
            type="button"
            class="navbar__menu-button"
            :class="{'text-brand-black': variant === 'dark'}"
            @click="mobileMenuOpen = true"
          >
            <span class="sr-only">Open main menu</span>
            Menu
          </button>
        </div>

        <ul class="hidden lg:flex lg:gap-x-12">
          <li
            v-for="{ value } in (groupedItems.page_link as NavbarItem[])"
            :key="value.page?.id"
          >
            <BaseLink
              :to="localePath({ name: 'slug', params: { slug: value.page?.slugs } })"
              @click="onClickLink(value.page?.url_path as string)"
            >
              {{ value.display_text }}
            </BaseLink>
          </li>
        </ul>
        <div class="hidden lg:flex lg:flex-1 lg:justify-end">
          <ul class="flex items-center gap-12">
            <li v-if="hasAvailableLocales">
              <BaseLangSwitch />
            </li>
            <template v-if="showCtas">
              <li
                v-for="item in (groupedItems.external_link as NavbarItem[])"
                :key="item.id"
              >
                <BaseButtonLink
                  v-if="item?.value?.link === '/login'"
                  variant="transparent"
                  :class="variant === 'dark' ? 'text-brand-black hover:bg-brand-primary/10' : 'text-brand-white hover:bg-brand-primary/10'"
                  :to="localePath(isAuthenticated ? '/customer/settings' : item.value.link)"
                >
                  {{ isAuthenticated ? 'Mi cuenta' : item.value.display_text }}
                </BaseButtonLink>

                <BaseButtonLink
                  v-else
                  :variant="getButtonVariant(item.value?.is_cta)"
                  :to="localePath(item.value.link as string)"
                >
                  {{ item.value.display_text }}
                </BaseButtonLink>
              </li>
            </template>
          </ul>
        </div>
      </nav>
    </div>

    <nav
      ref="$navbar"
      class="navbar"
      aria-label="Global navbar"
    >
      <div class="pr-20">
        <slot name="logo">
          <BaseCompanyLogo img-class="max-h-20 max-w-40 w-auto" />
        </slot>
      </div>
      <div class="flex lg:hidden">
        <button
          type="button"
          class="navbar__menu-button"
          :class="{'is-dark': variant === 'dark'}"
          @click="mobileMenuOpen = true"
        >
          <span class="sr-only">Open main menu</span>
          Menu
        </button>
      </div>
      <ul class="hidden lg:flex lg:gap-x-12">
        <li
          v-for="{ value } in (groupedItems.page_link as NavbarItem[])"
          :key="value.page?.id"
        >
          <BaseLink
            :to="localePath({ name: 'slug', params: { slug: value.page?.slugs } })"
            :variant="variant === 'dark' ? 'white' : 'primary'"
            @click="onClickLink(value.page?.url_path as string)"
          >
            {{ value.display_text }}
          </BaseLink>
        </li>
      </ul>
      <div class="hidden lg:flex lg:flex-1 lg:justify-end">
        <ul class="flex items-center gap-12">
          <li v-if="hasAvailableLocales">
            <BaseLangSwitch :variant="variant" />
          </li>
          <li v-for="item in (groupedItems.external_link as NavbarItem[])" :key="item.id">
            <BaseButtonLink
              v-if="item?.value?.link === '/login'"
              variant="transparent"
              :class="variant === 'light' ? 'text-brand-black hover:bg-brand-primary/10' : 'text-brand-white hover:bg-brand-primary/10'"
              :to="localePath(isAuthenticated ? '/customer/settings' : item.value.link)"
            >
              {{ isAuthenticated ? 'Mi cuenta' : item.value.display_text }}
            </BaseButtonLink>

            <template v-else>
              <BaseButtonLink
                v-if="showCtas"
                :variant="getButtonVariant(item.value?.is_cta)"
                :to="localePath(item.value.link as string)"
                @click="onClickCta(item.value.link as string)"
              >
                {{ item.value.display_text }}
              </BaseButtonLink>
            </template>
          </li>
        </ul>
      </div>
    </nav>
    <ClientOnly>
      <TransitionRoot
        appear
        :show="mobileMenuOpen"
        as="template"
      >
        <Dialog
          as="div"
          class="lg:hidden"
          @close="mobileMenuOpen = false"
        >
          <div class="fixed inset-0 z-50" />
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 translate-x-full"
            enter-to="opacity-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100"
            leave-to="translate-x-full"
          >
            <DialogPanel class="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-brand-primary sm:max-w-sm sm:ring-1 sm:ring-white/10">
              <header class="flex items-center justify-between bg-gray-50 px-6 py-3">
                <BaseCompanyLogo img-class="max-h-10 p-1" />
                <button
                  type="button"
                  class="-m-2.5 rounded-md p-2.5 text-brand-back font-medium"
                  @click="mobileMenuOpen = false"
                >
                  <span class="sr-only">Close menu</span>
                  Cerrar
                </button>
              </header>
              <div class="mt-6 flow-root px-6 pb-6">
                <div class="-my-6 divide-y divide-gray-500/25">
                  <ul class="space-y-2 py-6">
                    <li
                      v-if="hasAvailableLocales"
                      class="bg-white/60 rounded-md"
                    >
                      <BaseLangSwitch />
                    </li>
                    <li
                      v-for="{ value } in (groupedItems.page_link as NavbarItem[])"
                      :key="value.page?.id"
                    >
                      <NuxtLink
                        :to="localePath({ name: 'slug', params: { slug: value.page?.slugs } })"
                        class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white hover:bg-gray-800"
                        @click="onClickLink(value.page?.url_path as string)"
                      >
                        {{ value.display_text }}
                      </NuxtLink>
                    </li>
                    <li
                      v-for="{ value } in (groupedItems.external_link as NavbarItem[])"
                      :key="value.page?.id"
                    >
                      <NuxtLink
                        :to="localePath(value.link as string)"
                        class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white hover:bg-gray-800"
                        @click="onClickLink(value.link as string)"
                      >
                        {{ value.display_text }}
                      </NuxtLink>
                    </li>
                  </ul>

                  <div v-if="LOGIN_ENABLED === 'true'" class="py-6">
                    <NuxtLink
                      :to="localePath(isAuthenticated ? '/customer/settings' : '/login')"
                      class="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-white hover:bg-gray-800"
                      @click="onClickLink('/login')"
                    >
                      {{ isAuthenticated ? 'Mi cuenta' : 'Inicia sesión' }}
                    </NuxtLink>
                  </div>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </Dialog>
      </TransitionRoot>
    </ClientOnly>
  </header>
</template>

<script setup lang="ts">
import { ref, computed, inject, onMounted, onBeforeUnmount } from 'vue';
import { useAuth } from '@/stores/authStore';
import { Dialog, DialogPanel, TransitionRoot, TransitionChild } from '@headlessui/vue';
import BaseButtonLink from '~/components/BaseButtonLink.vue';
import type { BtnVariants, NavbarItem, Navbars, WebSettings } from '~/types';
import { groupBy, getSanitizedNavbarRoutes } from '~/lib/utils';
import BaseCompanyLogo from '~/components/BaseCompanyLogo.server.vue';
import BaseLink from '~/components/BaseLink.vue';
import BaseLangSwitch from '~/components/BaseLangSwitch.vue';
import { useGTM } from '~/composables';
import { useLocalePath, useI18n, useRuntimeConfig } from '#imports';
import { TRACKING_EVENTS } from '~/lib/constants';

const { locale } = useI18n();
const localePath = useLocalePath();
const { LOGIN_ENABLED } = useRuntimeConfig().app;

interface Props {
  variant?: 'light' | 'dark'
  stickyNavbar?: boolean
  showCtas?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  variant: 'light',
  stickyNavbar: true,
  showCtas: true,
});

const webSettings = inject('settings') as {
  navbar: Navbars['items'],
  settings: WebSettings,
};
const $navbar = ref<HTMLElement | null>(null);
const auth = useAuth();
const isAuthenticated = computed(() => auth.isAuthenticated);
const navbarItems = computed(() => {
  const currentNavbarByLocale = webSettings.navbar
    .find(item => item.locale === locale.value)?.menu_items as NavbarItem[] || [];

  return getSanitizedNavbarRoutes(currentNavbarByLocale, LOGIN_ENABLED === 'true');
});
const groupedItems = computed(() => groupBy<NavbarItem>(navbarItems.value, (item) => item.type));
const mobileMenuOpen = ref(false);
const stickyHeaderVisible = ref(false);
const hasAvailableLocales = webSettings.settings?.available_locales?.length > 1;

function getButtonVariant (isCta: boolean | undefined): BtnVariants {
  if (isCta) {
    return 'primary';
  }

  return 'transparent';
}

const { triggerEvent } = useGTM();
function onClickLink (url: string) {
  triggerEvent(TRACKING_EVENTS.CLICK_NAVBAR_LINK, { url });
}
function onClickCta (url: string) {
  triggerEvent(TRACKING_EVENTS.CLICK_NAVBAR_CTA, { url });
}

let intersectionObserver: any = null;

onMounted(() => {
  // Logic to show or hide the sticky navbar
  if (!props.stickyNavbar) return;
  intersectionObserver = new IntersectionObserver((entries) => {
    const [entry] = entries;
    stickyHeaderVisible.value = !entry.isIntersecting;
  }, {
    root: null,
    rootMargin: '0px',
    threshold: 0.1,
  });

  intersectionObserver.observe($navbar.value);
});

onBeforeUnmount(() => {
  if ($navbar.value && intersectionObserver) {
    intersectionObserver.unobserve($navbar.value);
  }
});
</script>

<style scoped>
.navbar {
  @apply container flex items-center justify-between;
  .navbar__menu-button {
    @apply text-brand-black;
    &.is-dark {
      @apply text-brand-white;
    }
  }
}
</style>
